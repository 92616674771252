import mapboxgl from 'mapbox-gl/dist/mapbox-gl.js';

$(document).ready(function () {
	// Satellite view toggle button control.
	// The logic for the ALA layer drawing lives here, since toggling requires
	// the layer to be redrawn, and the colour of the dots on the layer depends
	// on which map tiles are being used.
	class SatelliteControl {
		constructor(lsidName) {
			this._lsidName = lsidName;
		}
		onAdd(map) {
			this._map = map;
			this._isSatellite = false;
			// Bind listener methods to receive this SatelliteControl as `this`
			this._onClickSatellite = this._onClickSatellite.bind(this);
			this._drawLayer = this._drawLayer.bind(this);
			// Queue up the layer to be drawn on the initial load
			this._map.on('styledata', this._drawLayer);
			// Initialise the DOM element for the toglle button
	        this._container = document.createElement('div');
	        this._container.className = 'satellite-ctrl mapboxgl-ctrl mapboxgl-ctrl-group';
	        this._satelliteButton = document.createElement('button');
	        this._satelliteButton.textContent = 'Satellite View';
	        this._container.appendChild(this._satelliteButton);
	        this._satelliteButton.addEventListener('click', this._onClickSatellite);
	        return this._container;
		}
	    onRemove() {
	        this._container.parentNode.removeChild(this._container);
	        this._map = undefined;
	    }
	    _onClickSatellite() {
		    	this._isSatellite = !this._isSatellite;
	    		this._satelliteButton.textContent =
	    			this._isSatellite ? 'Map View' : 'Satellite View';
	    		// Change the map tiles to the current style, this resets sources+layers
	    		this._map.setStyle(
	    			this._isSatellite
	    			? 'mapbox://styles/mapbox/satellite-streets-v11?optimize=true'
	    			: 'mapbox://styles/mapbox/light-v10?optimize=true'
	    		);
	    		// Restore the ALA source+layer after the style change completes
	    		this._map.on('styledata', this._drawLayer);
	    }
	    _drawLayer() {
	    		if(!this._map.getLayer('ala-layer')){
	    			// Grey dots when in satellite mode, orange otherwise
	    			let dotColour = this._isSatellite ? 'dddddd': 'e6704c';
				this._map.addLayer({
					id: 'ala-layer',
					type: 'raster',
					source: {
						type: 'raster',
						tiles: [
							// Defining BBOX here, so curly braces don't get encoded
							'https://biocache.ala.org.au/ws/ogc/wms/reflect?BBOX={bbox-epsg-3857}&' +
							$.param({
								q: `lsid:${this._lsidName}`,
								SERVICE: 'WMS',
								REQUEST: 'GetMap',
								VERSION: '1.1.1',
								SRS: 'EPSG:3857',
								ATTRIBUTION: "Atlas of Living Australia",
								FORMAT: 'image/png',
								BGCOLOR: "0x000000",
								TRANSPARENT: true,
								ENV: `color:${dotColour};name:circle;size:4;opacity:0.8`,
								OUTLINE: false,
								WIDTH: 256,
								HEIGHT: 256
							})
						],
						tileSize: 256
					}
				});
	    		}
	    		// Only run once for each style
	    		this._map.off('styledata', this._drawLayer)
	    }
	}

	// Regular maps
	$('.js-leaflet-map').each(function (index, element) {
		const lat = parseFloat($(element).data('lat'));
		const lng = parseFloat($(element).data('lng'));
		const zoom = parseInt($(element).data('zoom'));
		const mapboxToken = $(element).data('mapbox-token');

		const map = new mapboxgl.Map({
			container: element,
			style: 'mapbox://styles/mapbox/streets-v11?optimize=true',
			center: [lng, lat],
			zoom: zoom,
			attributionControl: false,
			accessToken: mapboxToken
		});

		map.addControl(
			new mapboxgl.NavigationControl({showCompass: false}), 'top-left'
		);
		map.addControl(
			new mapboxgl.FullscreenControl(), 'top-left'
		);

		const marker = new mapboxgl.Marker()
			.setLngLat([lng, lat])
			.addTo(map);
		const credits = L.control.attribution().addTo(map);
	});

	// ALA maps
	$('.js-leaflet-ala-map').each(function (index, element) {
		const lsidName = $(element).data('lsid-name');
		const mapboxToken = $(element).data('mapbox-token');

		const map = new mapboxgl.Map({
			container: element,
			style: 'mapbox://styles/mapbox/light-v10?optimize=true',
			bounds: new mapboxgl.LngLatBounds(
				new mapboxgl.LngLat(112, -44),
				new mapboxgl.LngLat(159, -9)
			),
			attributionControl: false,
			accessToken: mapboxToken
		});

		map.addControl(
			new mapboxgl.NavigationControl({showCompass: false}), 'top-left'
		);
		map.addControl(
			new mapboxgl.FullscreenControl(), 'top-left'
		);
		// This also queues up the ALA layer for loading
		map.addControl(
			new SatelliteControl(lsidName), 'bottom-right'
		);
	});
});
